<template>
  <div class="demo">
    <!-- <div class="title">
      <div class="rentBig rent">
        <div class="pmsFont">
          <span>昨日设备使用率</span>
        </div>
        <div class="countNum">
          <count-to
            style="color: #00cc00"
            :start-val="initDevicePMS.device"
            :end-val="devicePMS.device || 0"
            :duration="3000"
            suffix="%"
          />
        </div>
      </div> -->
      <!-- <div class="rent">
        <div class="pmsFont">
          <span>设备使用率</span>
        </div>
        <div class="countNum">
          <count-to
            style="color: #00cc00"
            :start-val="initDevicePMS.device"
            :end-val="devicePMS.device || 0"
            :duration="3000"
            suffix="%"
          />
        </div>
      </div>
      <div style="background: linear-gradient(to right, #fe7b54 30%, #bf1d13)">
        <div class="pmsFont">
          <span>PMS入住率</span>
        </div>
        <div class="countNum">
          <count-to
            style="color: yellow"
            suffix="%"
            :start-val="initDevicePMS.pms"
            :end-val="devicePMS.pms || 0"
            :duration="3000"
          />
        </div>
      </div> -->
    <!-- </div> -->
    <div class="line chartBar" >
      <div class="changeTimeType">
        <span
          @click="changeTimeType(1)"
          class="timeType"
          :class="{ activeType: type == 1 }"
          >日</span
        >
        <span>/</span>
        <span
          :class="{ activeType: type == 2 }"
          class="timeType"
          @click="changeTimeType(2)"
          >月</span
        >
      </div>
      <div id="lineEcharts"></div>
    </div>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { GET_SEVEN_PMS, GET_MONTH_PMS } from "@/api";
import {
  title_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_Font,
  symbolSize_Font,
  lineSize,
  symbolSize,
} from "./font";

export default {
  components: {
    CountTo,
  },
  props: {
    height: {
      type: String,
      default: "21vh",
    },
    roleType: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      devicePMS: {
        device: 0,
        pms: 0,
      },
      initDevicePMS: {
        device: 0,
        pms: 0,
      },
      sevenPms: {
        dayRented: [],
        dayPMS: [],
        day: [1, 2, 3, 4],
      },
      type: 1,
    };
  },
  mounted() {
    this.lineEcharts = this.$echarts.init(
      document.getElementById("lineEcharts")
    );
    window.addEventListener("resize", () => {
      this.lineEcharts.resize();
    });
    this.getPms();
    this.pmcLineInter = null;
    this.pmcLineInter = setInterval(() => {
      this.getPms();
    }, 3600000);
  },
  beforeDestroy() {
    clearInterval(this.pmcLineInter);
  },
  methods: {
    getOptions(data) {
      return {
        title: {
          // text: this.type == 1 ? "日出租率与日PMS" : "月出租率与月PMS",
          text: this.type == 1 ? "日出租率" : "月出租率",

          left: 0,
          top: 0,
          textStyle: {
            // align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            lineHeight: h_Font(),
            fontSize: x_Font(),
          },
        },
        grid: {
          left: "8%",
          right: "8%",
          bottom: "0",
          top: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.day, //['1','2','3','4','5','6' ],
          axisLabel: {
            color: "#fff",
            fontSize: x_Font(), //字体大小
            // fontSize:  10,
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLabel: {
            fontSize: x_Font(),
            color: "#fff",
          },
          splitLine: {
            lineStyle: {
              color: "#ddd", // 颜色
              width: lineSize(), // 粗细
            },
          },
        },
        series: [
          {
            name: "设备出租率",
            type: "line",
            symbolSize: symbolSize_Font(), //设定实心点的大小
            // markPoint: {
            //   symbolSize: symbolSize(),
            //   itemStyle: {
            //     normal: {
            //       label: {
            //         show: true,
            //         fontSize: x_Font(),
            //       },
            //     },
            //   },
            //   data: [
            //     {
            //       type: "max",
            //       name: "最大值",
            //     },
            //     { type: "min", name: "最小值" },
            //   ],
            // },
            // stack: '总量',
            data: data.dayRented,
            lineStyle: {
              width: line_Font(), //设置线条粗细
            },
            itemStyle: {
              color: "#00CC00",
              fontSize: x_Font(),
            },
          },
          // {
          //   name: "PMS出租率",
          //   type: "line",
          //   symbolSize: symbolSize_Font(),
      
          //   data: data.dayPMS,
          //   lineStyle: {
          //     width: line_Font(), //设置线条粗细
          //   },
          //   itemStyle: {
          //     color: "yellow",
          //     fontSize: x_Font(),
          //   },
          // },
        ],
      };
    },
    // 获取七日内的pms 出租率情况
    async getSevenPms(data = { roleType: this.roleType }) {
      try {
        let res = await GET_SEVEN_PMS(data);
        ["dayRented", "dayPMS", "day"].forEach((item) => {
          this.$set(this.sevenPms, item, []);
        });
        res.data.data.forEach((item) => {
          this.sevenPms.dayRented.unshift(item.deviceRentedCount);
          this.sevenPms.dayPMS.unshift(item.occupancyRate);
          this.sevenPms.day.unshift(item.createTime);
        });
        // this.sevenPms.day.pop();
        // this.devicePMS.pms = this.sevenPms.dayPMS.pop();
        // this.devicePMS.device = this.sevenPms.dayRented.pop();
        // setTimeout(() => {
        //   this.initDevicePMS = this.devicePMS;
        // }, 2000);
      } catch (error) {}

      this.initLine();
      // this.sevenPms = res.data.data
    },
    initLine() {
      this.lineEcharts.setOption(this.getOptions(this.sevenPms));
    },
    changeTimeType(val) {
      if (this.type == val) return;
      this.type = val;
      this.lineEcharts.clear();
      this.getPms();
    },
    getPms() {
      if (this.type == 1) {
        this.getSevenPms();
      } else {
        this.getMonthPms();
      }
    },
    async getMonthPms(data = { roleType: this.roleType }) {
      try {
        let res = await GET_MONTH_PMS(data);
        ["dayRented", "dayPMS", "day"].forEach((item) => {
          this.$set(this.sevenPms, item, []);
        });
        res.data.data.forEach((item) => {
          this.sevenPms.dayRented.unshift(item.deviceRentedCount);
          this.sevenPms.dayPMS.unshift(item.occupancyRate);
          this.sevenPms.day.unshift(item.createTime);
        });
        // this.sevenPms.day.pop();
        // this.devicePMS.pms = this.sevenPms.dayPMS.pop();
        // this.devicePMS.device = this.sevenPms.dayRented.pop();
        // setTimeout(() => {
        //   this.initDevicePMS = this.devicePMS;
        // }, 2000);
      } catch (e) {}

      this.initLine();
    },
    resize() {
      this.lineEcharts.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
  //   设备出租
  .rentBig{
    width: 100%  !important;
  }
  .rent {
    background: linear-gradient(to right, #3f83d1 30%, #4693eb);
  }
  .title {
    display: flex;
    justify-content: space-between;
    height: 6.2vh;
    margin-bottom: 1.5vh;
    > div {
      width: 48%;
      font-family: PingFang SC;
      color: rgba(255, 255, 255, 1);
      border-radius: 0.2vw;
      text-align: center;
      .pmsFont {
        padding-top: 0.2vh;
        font-size: 1.2vh;
        line-height: 2vh;
        height: 2vh;
      }
      .countNum {
        height: 4vh;
        line-height: 4vh;
        font-size: 3vh;
      }
    }
  }

  .line {
    width: 100%;
    // height: 27vh;
    // height: 21vh;
    // height: calc(100%-200px);
    position: relative;
    box-sizing: border-box;
    #lineEcharts {
      width: 100%;
      height: 100%;
    }
  }
}
.changeTimeType {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 1.1vh;
  width: 3vw;
  text-align: right;
  height: 2vh;
  line-height: 2vh;
  z-index: 999;
  cursor: pointer;
  .timeType {
    padding: 0.5vh;
  }
  .activeType {
    color: #79bbff;
    font-weight: 600;
  }
}
</style>